@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');
/* @import url('https://fonts.cdnfonts.com/css/aquawax'); */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

:root{
     --font-family: 'Quicksand', sans-serif; 
     --banner-font:'Quicksand';
    
    
    
    --gradient-text: linear-gradient(89.97deg, #ff6600 1.84%, #F49867 102.67%);
    --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);

    --color-bg: #101010;
    --color-footer:#220d00;
    --color-secondaryfooter:#030303; 
    --color-blog:#f56506;
    --color-text: #81AFDD;
    --color-subtext: #FF8A71;
    --banner-text:rgb(255, 255, 255);
    --card-color:#202022;
    --hover-color:#1b1b1d;
    @font-face {
        --font-family: 'BAHNSCHRIFT';
        src: local('BAHNSCHRIFT'), url(./font/BAHNSCHRIFT.TTF) format('truetype');
        
    }
}


