/* .ap__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0.5rem 6rem;
}
.ap__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.ap__navbar-logo img{
    height: 100px;
    width: 200px;
}
.ap__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    
}
.ap__navbar-links-container{
    display: flex;
    flex-direction: row;
    
    
}
.ap__navbar-links a{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0 1rem;
    color: white;
    text-decoration: none;
    
}
.ap__navbar-menu{
    cursor: pointer;
    display: none;
    position: relative;
}
.ap__navbar-menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.ap__navbar-menu-container p {
    margin: 1rem 0;
    
}

.ap__navbar-menu-container a{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color:var(--banner-text);
    text-decoration: none;
    text-align: center;
    margin-right:2rem;
}
.ap__navbar-menu {
    display: flex;
    z-index: 99;
}

@media screen and (max-width: 1050px) {
    .ap__navbar-links {
        display: none;
    }

   
   
    

}

@media screen and (max-width: 700px) {
    .ap__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .ap__navbar {
        padding: 2rem;
    }

    .ap__navbar-menu-container {
        top: 20px;
    }

    
} */



.ap__navbar {
    font-family: var(--font-family);
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 4rem 3rem;
    background-color: transparent;
    /* background-color: var(--color-bg); */
    color: var(--banner-text);
    
}


@media screen and (max-width: 700px) {
    .ap__navbar {
        padding: 2rem 4rem;
    }
    
}

@media screen and (max-width: 550px) {
    .ap__navbar {
        padding: 0.5rem;
    }

    .ap__navbar-menu-container {
        top: 20px;
    }
    header nav{
        width: 100%;
    }
    .hero_container{
        height: 75vh;
    }

    .ap__banner {
        height: 100%;
    }


}

.ap__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.ap__navbar-logo img {
    height: 100px;
    width: 200px;
}


nav a {
    margin: 0 1rem;
    color: var(--banner-text);
    text-decoration: none;
}

/* 
nav a:hover {
	color: var(--secondaryColor);
} */

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: white;
    visibility: visible;
    opacity: 1;
    font-size: 1.8rem;
}

header div,
nav {
    display: flex;
    align-items: center;
}

header .nav-btn {
    visibility: visible;
    opacity: 1;
}


header nav {

    position: fixed;
    top: 0;
    right: 0;
    /* left: 0; */
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    /* background-color: var(--gradient-text); */
    background: linear-gradient(90deg, #240900 1%, #000000 100%);
    transition: 1s;
    transform: translateX(100vw);
    height: 100vh;
    border-radius: 10px;
    z-index: 999;
    opacity: 0.9;


    overflow: auto;
    background: linear-gradient(315deg, #240900 3%, #101010 38%, #220d00 68%, #5e2702 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
}



@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

@media only screen and (max-width: 550px) {
    
    header nav{
        width: 100%;
    }


}

header .responsive_nav {
    transform: none;
    
}

nav .nav-close-btn {
    position: absolute;
    top: 2.5rem;
    right: 4.5rem;
}

nav a {
    font-size: 1.5rem;
}

