.ap__contact{
display: flex;
flex-direction:row  
}
.ap__contact-container{
    display: flex;
    flex-direction: column;
}
.ap__contact-miniheader{
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 800;
    
}
.ap__contact-header{
    font-size: 50px;
    font-weight: 600;
    font-family: var(--banner-font);
    color: var(--banner-text);
    line-height: 2;
    justify-content: center;
}
.ap__contact-text{
    font-family: var(--font-family);
    font-size: 18px;
    color: var(--banner-text);
}
.ap__contact-address{
    margin-top: 2rem;
    margin-right: 4rem;
    width: 40%;
}
.ap__contact-address-container-card{
    display: flex;    
    /* padding-block: 1rem; */
    margin-block:1rem;
    align-items: center;
}
.ap__contact-address-container-card-icon{    
    padding-right:0.5rem ;
}

.ap__contact-address-container-card-text{
    padding-left: 0.5rem;
    font-size: 18px;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--banner-text);
}
.ap__contact-form{
    font-family: var(--font-family);
    color: var(--banner-text);
    background-color: var(--color-bg);
    width: 60%;
    /* margin-top: 2rem;
    margin-left: 4rem;
    width: 50%; */
    
}
.ap__contact-form-input{
    display: flex;
    flex-direction: column;
    /* padding: 1rem;     */
}
.ap__contact-form-input input{
    margin-top: 1rem;
    background-color: var(--card-color);
    padding: 1rem 1rem;
   
    font-size: 14px;
    color: var(--banner-text);
}
.ap__contact-form-input textarea{
    margin-top: 1rem;
    background-color: var(--card-color);
    padding: 1rem 1rem;
    
    font-size: 14px;
    color: var(--banner-text);
}
.ap__contact-form-input input::placeholder{
    color: var(--banner-text);
}
.ap__contact-form-input textarea::placeholder{
    color: var(--banner-text);
}
.ap__contact-form-input button{    
    max-width: 15rem;
    margin-top: 1rem;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    background: transparent;
    border: 2px solid #ff6600;
    padding: 10px;
    color: white ;
    cursor: pointer;
    border-radius: 5px;
    
}

.ap__contact-form-input button:hover{
    background: #ff492091;
    border: 2px solid #FF4820;
    padding: 10px 15px;
    color: #fff;
    border-radius: 10px;
    
}

.ap__contact-form-body{
    display: flex;
    justify-content: space-around;
}



/* Form Messages */
.messageBox{
    width: 100%;
    position: relative;
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    display: none;
    /* justify-content: center; */
}

.messageBox .success{
    font-size: 20px;
    background: green;
    color: white;
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    animation: buttons .3s linear;
    display: none;

}

.messageBox .danger{
    font-size: 20px;
    background: red;
    color: white;
    position: absolute;
    transition: .3s;
    animation: buttons .3s linear;
    display: none;
}

@keyframes buttons {
    0%{
        transform: scale(0.1);
    }
    50%{
        transform: scale(0.5);
    }
    100%{
        transform: scale(1);
    }
}
@media screen and (max-width:1050px){
    .ap__contact{
        flex-direction: column;
        margin: 0 0 3rem;
    }

}
@media screen and (max-width:650px) {
    .ap__contact{
        display: block;
        margin:2px 0px;
    }
    .ap__contact--miniheader{
        margin-top: 2rem;
        font-size: 14px;
    }
    .ap__contact-header{
    line-height: 2;
    margin-top: 1rem;  
    font-size: 36px;  
    }
    .ap__contact-content{
        margin-top: 1rem;
    }
    .ap__contact-text{
        font-size: 14px;
    }
    .ap__contact-card{
        flex-direction: column;
    }
    .ap__contact-form{
        width: 100%;
    }
    .ap__contact-address{
        width: 100%;
    }
    
    .ap__contact-address-container-card-text{
        font-size: 14px;
    }
}    
@media screen and (max-width: 540px) {
    .ap__contact {
        flex-direction: column;
        padding: 3rem 1.5rem;
    }
    
    .ap__contact-form{
        font-family: var(--font-family);
        color: var(--banner-text);
        background-color: var(--color-bg);
       
        
    }
    .ap__contact-form-body {
       flex-direction: column;
    }
   

    
}
