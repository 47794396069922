.ap__banner {
    display: flex;
    flex-direction: row;
    /* display: none; */
}
.hero_container{
    height: 100vh;
}


.ap__banner-content {
    flex: 1;
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-direction: column;
    color: var(--gradient-text);
    margin-left: 3rem;
    z-index: 2;
}
.myTypist{
    color:var(--banner-text);
}
.ap__banner-content h1 {
    font-family: var(--banner-font);
    font-weight: 600;
    font-size: 60px;
    line-height: 75px;
    letter-spacing: -0.04rem;
}

.ap__banner-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: var(--banner-text);

    margin-top: 1.5rem;
}

.ap__banner-content-button {
    width: auto;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.ap__banner-content-button a{
    text-decoration: none;
    color: var(--banner-text);
}


.ap__banner-content-button button {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    background: transparent;
    border: 2px solid #ff6600;
    padding: 10px 15px;
    color: white ;
    cursor: pointer;
    border-radius: 5px;
}

.ap__banner-content-button button:hover {
    background: #ff492091;
    border: 2px solid #FF4820;
    padding: 10px 15px;
    color: #fff;
    border-radius: 10px;
}

.ap__banner-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ap__banner-image img {
    width: 100%;
    /* height: 100%; */
}
@media screen and (max-width: 1050px) {
    .ap__banner {
        flex-direction: column;
    }

    .ap__banner-content {
        margin: 0 0 3rem;
    }
    .ap__banner-image{
        display: none;
    }
    .ap__about-image{
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .ap__banner {
        display: flex;
        flex-direction: row;
        
    }
    .ap__banner-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .ap__banner-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    


    
    .ap__banner-content-button {
        font-size: 16px;
        line-height: 24px;
        width: auto;
    }
}

@media screen and (max-width: 490px) {
    .ap__banner-content h1 {
        font-size: 40px;
        line-height: 30px;
    }

    .ap__banner-content p {
        font-size: 18px;
        line-height: 24px;
    }

    .ap__banner-content-button {
        font-size: 12px;
        line-height: 16px;
        width: auto;
    }

}

.hero-subtitle {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  @media (min-width: 768px) {
    .hero-subtitle {
      font-size: 2.5rem;
    }
  }
  
