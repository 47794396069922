.ap__projects{
       align-items: flex-start
}

.ap__client_section_padding{
    padding: 2rem 5rem;
    background: linear-gradient(90deg, #000 1%, #240900 100%);
}


@media screen and (max-width:540px){
    .ap__client_section_padding{
        padding:0 2rem;
    }

    .ap__projects-card-content{
        align-items: center;
        padding: 1rem;
    }
    .ap__projects-card{
        flex-direction: column;
    }

  }




.ap__projects-card{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.ap__projects-header{
    font-size: 50px;
    font-weight: 600;
    font-family: var(--banner-font);
    color: var(--banner-text);
    line-height: 2;
    text-align: center;
   
    justify-content: center;
}
.ap__projects-card-content{
    display: flex;
    align-items: center;
    padding: 1rem;
    /* height: 300px; */
    width: 300px;
}
/* .ap__projects-card-content:hover{
    box-shadow: var(--color-blog) 0px 28px 60px;
    filter: grayscale(0);
}     */
.ap__projects-card-content img{
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
}
   
@media screen and (min-width:1050px ) and (max-width:540px){
    .ap__projects-card{
        flex-direction: column;
    }

}
@media screen and (max-width:650px) {
    .ap__projects-card{
        display: flex;
        /* flex-direction: column; */
        flex-wrap: wrap;
        
    }
    .ap__projects-card-content{
        /* height: 300px; */
        /* width: 300px; */
        width: 33%;
    }
    .ap__projects-header {
        font-size: 36px;
    }
    
}


.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
    
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
   
    
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #d0cdcd;
    padding-inline: 14px;
    border-radius: 3px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;
}
.modal h2{
    color: #114c78;
    font-size: 2rem;
}

.close-modal {
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    cursor: pointer;
}

.close-modal:hover {
    color: red;
}

.ap__modal-card-content{
    display: flex;
    align-items: center;
    padding: 1rem;
    height: 350px;
    width: 350px;
    
    
    }
.ap__modal-card-content img{
    width: 100%;
    border-radius: 10px;
}


.modal_url{
    font-size: 1.2rem;
    color: #114c78;
    text-decoration: none ;
    margin-block: 5px;
}

.modal_url:hover{
    color: red;
}

.modal_desc{
    text-align: justify;
    font-size: 17px;
    padding-inline: 10px;
    color: black;
}