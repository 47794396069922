.ap__services-miniheader{
    display: block;
    font-size: 18px;
    font-weight: 800;
    font-family: var(--banner-font);
    color: var(--gradient-text);
}
.ap__services-header{
    display: block;
    font-size: 50px;
    font-weight: 600;
    font-family: var(--banner-font);
    color: var(--banner-text);
    line-height: 2;
    justify-content: center;
}
.ap__services-text{
    display: flex;
    font-size: 18px;
    font-family: var(--font-family);
    color: var(--banner-text);
    
}
.ap__service-card{
    display: flex;
    /* flex-direction: row; */
    margin-top: 2rem;
    justify-content: space-between;
    flex-wrap: wrap;

}
.ap__service-card-content{
    /* flex-direction: column; */
    /* flex: 1; */
    padding: 1rem;
    margin-block:10px;
    /* margin-left: 0px; */
    background: var(--card-color);
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
    /* align-items: left; */
    border-radius: 10px;
    width: 32.5%;
  
}
  
.ap__service-card-content:hover{
    border-bottom: 2px solid var(--color-blog);
    background: var(--hover-color);
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 
}
.ap__service-card-content-title {
    margin-top: 1rem;
    font-family: var(--banner-font);
    font-weight:500;
    font-size: 30px;
    color: var(--banner-text);
}
.ap__service-card-content-text{
    margin-top: 1rem;
    font-family: var(--banner-font);
    font-size: 18px;
    color: var(--banner-text);
    font-weight: 100;
}
.ap__service-card-content-knowmore{
    margin-top: 1rem;
    
}
.ap__service-card-content-knowmore a{
    
    font-family: var(--font-family);
    text-decoration: none;
    color: #f56506;
    cursor: pointer;
    

}


@media screen and (min-width:576px) and (max-width:768px){
    .ap__service-card-content{
        display: block;
        width: 46%;
    }


}

/* @media screen and (min-width:768px) and (max-width:992px){
    .ap__service-card-content{
        display: block;
        width: 100%;
    }


} */

@media screen and (max-width:576px) {
    .ap__service-card{
        display: block;
    }
    .ap__service-card-content{
        display: block;
        width: 100%;
    }
    .ap__services-miniheader {
        font-size: 14px;
    }
    .ap__services-text {
        font-size: 14px;
    }
    .ap__services-header{
        font-size: 36px;
    }
    .ap__service-card-content-text{
        font-size:14px;
    }
    .ap__service-card-content-title{
        font-size: 24px;
    }
}