.ap__footer {
     
    /* background-color: var(--color-footer); */
    background-color: var(gradient__bg);
    
}
.ap__footer-section-logo{
    text-align: center;
}
.ap__footer-content{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
}   
.ap__footer-section{
    /* flex-direction: column;
    flex: 1; */
    padding-left: 1rem ;
    padding-right: 1rem;
}

.ap__footer-section h2{
    font-family: var(--font-family);
    color: var(--banner-text);
    padding-inline:1rem;

}

.ap__footer-section-logo img{
    height: 150px;
    width: 300px;
}

.ap__footer-section-logo-text{
    font-family: var(--font-family);
    color: var(--banner-text);
    font-size: 15px;
    /* text-transform: uppercase; */
    font-weight: 600;
}
/* .ap__footer-section-links{
   align-items: center;
   padding-inline: 1rem;
   margin-inline:1rem;
   
}
.ap__footer-section-links a{
    text-decoration: none;
    font-family: var(--font-family);
    color: var(--banner-text);
    font-size: 18px;
    padding: 0.5rem;
    display: block;
     text-align: center;
} */

.ap__footer-section-title {
    font-family: var(--font-family);
    color: var(--banner-text);
    font-size: 24px;
    padding: 0.5px;
    font-weight: 800;
    line-height: 2;
}
.ap__footer-section-address{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-block: 2rem;
    
}
.ap__footer-section-address-content{
    display: flex;
    align-items: center;
    margin-block: 10px;
}
.ap__footer-section-adress-icon{
    display: flex;
    
   
}
.ap__footer-section-address-icon-text{
    font-family: var(--font-family);
    font-size: 18px;
    color: var(--banner-text); 
    margin-inline-start: 5px 
    
}
.ap__secondary-footer{
    display: flex;
    background-color: var(--color-secondaryfooter);
    align-items: center;
    flex-direction: row;
    border-top: 1px white solid;
}
.ap__secondary-footer-content{    
    display: flex;
    padding: 1rem 4rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.ap__secondary-footer-copyright{
    display: flex;
    align-items: flex-start;
    font-family: var(--font-family);
    color: var(--banner-text);
}
/* .ap__secondary-footer-social{
    display: flex;
    align-items: flex-end;

} */

.ap__secondary-footer-social a{
    margin-inline: 5px;
}
@media screen and (max-width:1050px){
   
.ap__footer-section h2 {
    padding-inline: 0;
}
}
@media screen and (max-width:650px) {
    .ap__footer-content{
    flex-direction: column;
    }
    .ap__secondary-footer-content {
        flex-direction: column-reverse;
        padding-block: 10px;
        padding-inline: 1px;

    }
}    
@media screen and (max-width: 550px) {
    .ap__secondary-footer-content {
        flex-direction: column-reverse;
        font-size: 12px;
        padding-block: 10px;
        padding-inline: 1px;

    }
}
   